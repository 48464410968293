@import '../../../default';

.ui-loading{

    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $color-background-loading;
    align-items: center;
    justify-content: center;

    img{
        animation: animation_loading 600ms linear infinite alternate;
    }

    &.show{
        display: flex;
    }

    &.hidden{
        display: none;
    }

    @keyframes animation_loading {
        0%{
            filter: drop-shadow(0  0 0px  #FFF) drop-shadow(0  0 0px  #FFF) drop-shadow(0  0 0px  #FFF);
        }

        100%{
            filter: drop-shadow(0  0 0px  #FFF) drop-shadow(0  0 5px  #FFF) drop-shadow(0  0 10px  #FFF);
        }
    }

}