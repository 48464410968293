@import '../../default';

.page-user{
    width: 100vw;
    height: auto;
    min-height: 100vh;
    display: grid;
    grid-template-columns: 80px auto;
    padding: 0;

    &::before{
        display: block;
        width: 80px;
        height: 100%;
        content: '';
    }

    > header{
        background-color: $color-background-register;
        width: 100%;
        max-width: 80px;
        height: 100vh;
        box-sizing: border-box;
        padding: 5px;
        display: grid;
        grid-template-rows: 75px auto 60px;
        align-items: center;
        text-align: center;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 90;

        .logo{
            img{
                display: block;
                width: 100%;
            }
        }

        .area-menu{
            display: flex;
            justify-content: center;
            flex-direction: column;

            > div {
                margin: 0 auto;
                font-size: 2em;
                color: #FFF;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                text-align: center;
                border-radius: 50%;
                position: relative;

                &::before{
                    content: attr(tooltip);
                    display: block;
                    position: absolute;
                    z-index: 50;
                    top: 50%;
                    right: 0;
                    background-color: $color-background-register;
                    transform: translate(80%, -50%);
                    font-size: 0.5em;
                    box-sizing: border-box;
                    padding: 5px 10px;
                    border-radius: 5px ;
                    transition: all 600ms 300ms ease;
                    opacity: 0;
                    pointer-events: none;
                }

                i{
                    position: relative;
                    z-index: 60;
                }

                &:hover{
                    background-color: rgba(255, 255, 255, 0.2);

                    &::before{
                        content: attr(tooltip);
                        display: block;
                        position: absolute;
                        z-index: 50;
                        top: 50%;
                        right: 0;
                        background-color: $color-background-register;
                        transform: translate(105%, -50%);
                        font-size: 0.5em;
                        box-sizing: border-box;
                        padding: 5px 10px;
                        border-radius: 5px ;
                        transition: all 600ms ease;
                        opacity: 1;
                    }
                }
            }
        }
    }

    > section{
        display: grid;
        grid-template-rows: 50px auto;
        row-gap: 5px;
        box-sizing: border-box;
        padding: 15px;

        &::before{
            display: block;
            width: 100%;
            height: 50px;
            content: '';
        }
        
        > header{
            box-sizing: border-box;
            padding: 15px;
            border-bottom: #eee solid 1px;
            display: grid;
            grid-template-columns: auto 150px 60px;
            align-items: center;
            position: fixed;
            background-color: #FFF;
            top: 0;
            right: 0;
            width: calc(100% - 100px);
            z-index: 1000;

            .name{
                color: $color-background-register;
            }

            .clock{
                .component-clock{
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    font-size: 16px;
                    justify-content: end;

                    .date{
                        font-size: 0.8em;
                        font-family: 'Roboto', sans-serif;
                    }

                    .time{
                        font-size: 0.8em;
                        font-family: 'Roboto', sans-serif;
                    }
                }
            }

            .area-user{
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }
        > .content{
            box-sizing: border-box;
            padding: 15px;
        }
    }

    &:has(header){
        section{
            padding: 0;

            .content{
                padding: 0;
            }
        }
    }
}