@import '../../default';

.page-login{
    width: 100vw;
    height: 100vh;
    background-color: $color-background-register;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    flex-direction: column;
    position: relative;

    &::before{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top:0;
        left: 0;
        background-image: url(../../../image/bg.jpg);
        background-size: cover;
        opacity: 0.2;
    }

    .area-box{
        position: relative;
        z-index: 100;
        margin: 15px auto;
        
        .logo{
            text-align: center;
        }

        .box{
            width: 100%;
            max-width: 400px;
            min-width: 300px;

            header{
                font-size: 1.5em;
                font-weight: 800;
                text-align: center;
            }
        }
    }
}