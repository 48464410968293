@import '../../../default';

.page-home-user{

    .page-content{
        box-sizing: border-box;
        padding: 15px;
        max-width: calc(100dvw - 100px);
    }

    .topo{
        min-height: 200px;
        box-sizing: border-box;
        padding: 20px;
        padding-bottom: 50px;
        background-color: var(--blue-50);
        display: grid;
        grid-template-columns: auto;
        gap: 10px;

        > .timer{
             grid-column:  2 / span 3;
             align-items: center;
             display: flex;

             .date{
                font-size: 1em !important;
                align-items: center;
                justify-content: center;
                display: flex;
                color: var(--blue-600);
                font-family: 'Roboto', sans-serif;
             }
             .time{
                 font-size: 2em !important;
                 align-items: center;
                 justify-content: center;
                 display: flex;
                 color: var(--blue-400);
                 font-family: 'Roboto', sans-serif;
             }
        }
    }

    .resume{
        margin-top: -50px;
    }

    .buttons{
        margin-top: -40px;
        margin-bottom: 40px;
    }

    .card{
        background-color: var(--bg);
        color: var(--text);
        box-sizing: border-box;

        padding: 20px;
        border-radius: 5px;
        display: grid;
        grid-template-columns: auto 32px;
        row-gap: 5px;

        .title{
            font-weight: 800;
        }

        .icon{
            text-align: right;
        }

        .content{
            grid-row: 2;
            grid-column: 1 / span 2;
            text-align: right;
            border-top: 1px dashed;
            border-top-color: var(--text);
            box-sizing: border-box;
            padding: 10px 0 0;
        }
    }

    .area-actions{
        .p-button{
            .p-button-icon{
                font-size: 20px !important;
            }
            .p-button-label{
                align-items: center;
                display: flex;
            }
        }
    }

}