@import '../../default';

.component-journey-day{

    box-sizing: border-box;
    padding: 0px;

    .bar-journey{
        width: 100%;
        height: 20px;
        border-radius: 10px;
        border: 1px #ccc solid;
        display: grid;
        grid-template-columns: 0% 100% 0%;
        overflow: hidden;


        .normal{
            background-color: var(--green-100);
        }

        .pendence{
            background-color: var(--yellow-100);
        }

        .extra{
            background-color: var(--blue-100);
        }
    }

    .box{
        display: grid;
        grid-template-columns: 120px auto;
        row-gap: 15px;
        column-gap: 15px;

        .clock{
            text-align: center;

            .time-day{
                text-align: center;
                font-size: 0.7em;
                color: $color-background-register;
                position: relative;
                top: 0;
                transform: translateY(-100%);
                background-color: #FFF;
            }
        }

        .content{
            display: grid;
            grid-template-columns: repeat(5, auto);
            row-gap: 15px;
            column-gap: 15px;
            margin-top: 10px;

            > div{
                .title{
                    width: 100%;
                    font-size: 0.8em;
                    text-align: center;
                    font-weight: 600;
                    background-color: #eee;
                    box-sizing: border-box;
                    padding: 5px;
                    color: $color-background-register;
                }

                .title + div{
                    text-align: center;
                    box-sizing: border-box;
                    padding: 10px;
                    color: $color-background-register;

                    &:empty{
                        &::before{
                            content:'-:-';
                            display: inline;
                            
                        }
                    }
                }
            }

            .area-clock{
                align-items: center;
                display: flex;
                grid-column: 1 / span 2;

                .clock{
                    width: 100%;
                    display: grid;
                    grid-template-columns: repeat(auto-fill, minmax(70px, auto));
                    column-gap: 15px;
                    font-size: 0.8em;
                    color: $color-background-register;

                }
            }

            .area-journey{
                display: flex;
                align-items: center;
                font-size: 0.8em;
                color: $color-background-register;
                text-align: center;
                grid-column: 3 / span 3;
            }

            .area-register{
                grid-column: 3 / span 2;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }

            .area-details{
                grid-column: 5;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                flex-direction: column;
            }

        }
    }

    .details{
        display: grid;
        grid-template-columns: 30px auto;
        column-gap: 5px;
        height: 30px;
        align-items: center;
        border-bottom: #eee solid 1px;
        cursor: pointer;
        color: $color-background-register;
        font-weight: 600;
    }

}