@import '../../../default';

.page-collaborator-view{

    box-sizing: border-box;
    padding: 20px;
    width: calc(100dvw - 100px);

    .header{
        box-sizing: border-box;
        padding: 5px;
        margin: 5px auto 15px;
        width: 100%;
        height: auto;
        min-height: 30px;
        border-radius: 5px;
        background-color: var(--gray-100);
        box-shadow: 0 0 2px #000;
        display: flex;
        justify-content: flex-end;
    }

    .avatar{
        width: 100%;
        height: 100%;
        background-color: var(--gray-100);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .grid-form{
        display: grid;
        grid-template-columns: 90px 1fr;
        align-items: center;
        row-gap: 10px;

        .title{
            font-weight: 800;
        }

        .text{
           > .p-inputtext{
                width: 230px;
            }
        }
    }

    .card{
        background-color: var(--bg);
        color: var(--text);
        box-sizing: border-box;
        padding: 20px;
        border-radius: 5px;
        display: grid;
        grid-template-columns: auto 32px;
        row-gap: 5px;

        .title{
            font-weight: 800;
        }

        .icon{
            text-align: right;
        }

        .content{
            grid-row: 2;
            grid-column: 1 / span 2;
            text-align: right;
            border-top: 1px dashed;
            border-top-color: var(--text);
            box-sizing: border-box;
            padding: 10px 0 0;
        }
    }
}