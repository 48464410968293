@import '../../../default';


.page-feedback-answer{
    
    .question{


        display: grid;
        grid-template-columns: auto 300px;
        column-gap: 15px;
        row-gap: 15px;
        box-sizing: border-box;
        padding: 10px;

        .number{
            font-size: 1.2em;
            font-weight: 800;

            &::after{
                content: '.';
                display: inline-block;
            }
        }

        + .question{
            border-top: 1px solid #eee;
            margin-top: 15px;
        }

        .ask{
            display: grid;
            grid-template-columns: 50px auto;
        }

        &.star{
            .aswers{
                display: flex;
                text-align: right;
                align-items: center;
                justify-content: flex-end;
            }
        }

        &.text{
            grid-template-columns: auto;

            .aswers{
                display: flex;
                text-align: left;
            }
        }
    }
}