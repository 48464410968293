@import '../../default';

.component-clock{
    width: 100%;
    font-size: 18px;
    font-family: 'Big Shoulders Stencil Text', cursive;
    text-align: center;

    .date{
        font-size: 2em;
    }

    .time{
        font-size: 5em;
    }

}