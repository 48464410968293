@import 'default';

@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Stencil+Text&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Stencil+Text&family=Roboto&display=swap');

@import "primereact/resources/themes/lara-light-indigo/theme.css"; 
@import "primereact/resources/primereact.min.css";                 
@import '/node_modules/primeflex/primeflex.css';
@import "primeicons/primeicons.css"; 


html{

    width: 100%;
    height: 100%;

    body{

        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;

        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        overflow-x: hidden;

        #root{
            width: 100vw;
            height: auto;
            min-height: 100vh;
        }
    }
}