@import '../../default';

.component-journey{
    display: grid;
    width: 100%;
    grid-template-columns: 130px auto;
    grid-template-rows: minmax(100, auto) auto;
    row-gap: 10px;
    column-gap: 10px;
    background-color: #bae8fa;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;

    .area-date{
        grid-row: 1 / span 2;
        
        display: grid;
        grid-template-rows: repeat(2, auto);
        
        box-sizing: border-box;
        padding: 5px;

        background-color: rgba(255, 255, 255, 0.2);
        color: $color-background-register;

        .box{
            display: grid;
            grid-template-columns: 20px auto;

            .title{
                text-align: center;
                font-weight: 800;
                grid-row: 1 / span 2;
                writing-mode: tb;
                transform: rotate(-180deg);
                box-sizing: border-box;
                position: relative;
                font-weight: 200;

                &::before{
                    position: absolute;
                    top: 0;
                    left: -5px;
                    content: '';
                    display: block;
                    width: 1px;
                    height: 100%;
                    background-color: $color-background-register;

                }

            }
    
            .month{
                text-align: center;
                font-size: 1em;
                grid-column: 2;
            }
    
            .year{
                text-align: center;
                grid-column: 2;
                font-size: 1.5em;
            }
        }

    }

    .area-bar{
        .progress-bar{
            width: 100%;
            height: 20px;
            background-color: #ccc;
            border-radius: 5px;
            display: flex;
            flex-direction: row;

            > div{
                width: var(--percent);
                height: 100%;
                position: relative;
                overflow: hidden;

                &::before{
                    position: absolute;
                    content: attr(value)'%';
                    display: block;
                    font-size: 0.8em;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                }

                &.bar-positive{
                    color: #0f5132;
                    background-color: #d1e7dd;
                    border-color: #badbcc;
                }

                &.bar-negative{
                    color: #842029;
                    background-color: #f8d7da;
                    border-color: #f5c2c7;
                }

                &.bar-extra{
                    color: #664d03;
                    background-color: #fff3cd;
                    border-color: #ffecb5;
                }
            }
        }
    }

    .area-info{
        display: grid;
        grid-template-columns: auto 250px;
        align-items: end;

        .area-buttons{
            box-sizing: border-box;
            padding: 0 10px ;
            color: $color-background-register;
            display: flex;

            > div{
                display: flex;
                align-items: center;
                justify-content: center;
                width: auto;
                height: auto;
                cursor: pointer;
                border-bottom: 1px solid transparent;
                transition: border ease 600ms 300ms;
                box-sizing: border-box;
                padding: 0 5px;

                + div{
                    margin-left: 10px;
                }

                &:hover{
                    border-bottom: 1px solid $color-background-register;
                    transition: border ease 600ms;
                }
            }
        }

        .area-text{
            text-align: right;
            color: $color-background-register;
        }
    }
}

div[data*="resume-journey"]{
    .p-datatable-thead{
        position: sticky;
        top: 0;
        left: 0;
    }
}